import React, { useRef } from 'react'

import { Navigation } from 'src/components/navigation'
import { useSetMetatag, useSetTitle } from 'src/contexts/Metatags'
import { useTranslation } from 'src/contexts/i18n'
import { useSetupModulesForPage } from 'src/contexts/modules'
import { PageNameProvider } from 'src/contexts/pageName'
import { useTrackPageView } from 'src/contexts/tracking'

export function Home() {
  const { t } = useTranslation()

  useSetupModulesForPage()
  useTrackPageView('Home')

  const mainContainer = useRef(document.createElement('div'))
  mainContainer.current.style.width = '100%'
  mainContainer.current.style.display = 'flex'

  const title = `Handschriftenportal: ${t('topBar.logoLink')}`
  const desc = t('description')

  useSetTitle(title)
  useSetMetatag({ key: 'name', value: 'description', content: desc })

  return (
    <PageNameProvider value="home">
      <Navigation main={mainContainer.current} module={'home'} />
    </PageNameProvider>
  )
}
