import React from 'react'
import { useLocation } from 'react-router-dom'

import {
  ParsedSearchParams,
  useParsedSearchParams,
} from 'src/utils/searchparams'

import { ExtendedSearchView } from './ExtendedSearchView'
import { ListView } from './ListView'
import { NormOverview } from './NormOverview'
import { Overview } from './Overview'

export function Search() {
  const { hspobjectid, authorityfileid }: ParsedSearchParams =
    useParsedSearchParams()

  const { pathname } = useLocation()
  let children = null

  if (hspobjectid) {
    children = <Overview />
  } else if (pathname === '/search/extended') {
    children = <ExtendedSearchView />
    // }
    // else if (authorityfileid && pathname === '/search/authority-files') {
    //   children = <NormOverview />
  } else {
    children = <ListView />
  }

  return <div id="hsp-search-main">{children}</div>
}
