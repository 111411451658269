import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Navigation } from 'src/components/navigation'
import { searchActions } from 'src/contexts/actions/searchActions'
import { useSetupModulesForPage } from 'src/contexts/modules'
import { PageNameProvider } from 'src/contexts/pageName'
import { searchSelectors } from 'src/contexts/state'
import { useTrackPageView } from 'src/contexts/tracking'
import {
  ParsedSearchParams,
  useParsedSearchParams,
} from 'src/utils/searchparams'

export function Search() {
  const mainContainer = useRef(document.createElement('div'))
  mainContainer.current.style.width = '100%'

  const dispatch = useDispatch()
  const params: ParsedSearchParams = useParsedSearchParams()
  const { hspobjectid, fromWorkspace } = params
  const searchParams = useSelector(searchSelectors.getSearchParams)

  useSetupModulesForPage()
  useTrackPageView('Search')

  useEffect(() => {
    if (params.fromWorkspace) {
      const newParams = {
        ...searchParams,
        hspobjectid,
        fromWorkspace,
      }
      dispatch(searchActions.setSearchParams(newParams))
    } else {
      dispatch(searchActions.setSearchParams(params))
    }
  }, [params])

  return (
    <PageNameProvider value="search">
      <Navigation main={mainContainer.current} module="search" />
    </PageNameProvider>
  )
}
