import React from 'react'
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
} from 'react-router-dom'

import { SkipLink } from 'src/components/navigation/shared/SkipLink'
import { Cms } from 'src/pages/Cms'
import { Home } from 'src/pages/Home'
import { Projects } from 'src/pages/Projects'
import { Search } from 'src/pages/Search'
import { Workspace } from 'src/pages/Workspace'

import { cmsLoader, homeLoader, projectLoader } from './loader'

function Layout() {
  return (
    <>
      <SkipLink />
      <Outlet />
      <ScrollRestoration
        getKey={(location) => {
          const paths = ['/search']
          return paths.includes(location.pathname)
            ? location.search
            : location.pathname
        }}
      />
    </>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: 'search/*',
        element: <Search />,
      },
      {
        path: 'workspace',
        element: <Workspace />,
      },
      {
        path: 'info/:slug',
        element: <Cms />,
        loader: cmsLoader,
      },
      {
        path: 'projects',
        element: <Projects />,
        loader: projectLoader,
      },
      {
        index: true,
        element: <Home />,
        loader: homeLoader,
      },
    ],
  },
])

export function Router() {
  return <RouterProvider router={router} />
}
