import React from 'react'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { MobileNavigation } from './mobile'
import { WebNavigation } from './web'

interface Props {
  main?: Element
  module?: string
}

export function Navigation(props: Props) {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })

  return mobile ? (
    <MobileNavigation main={props.main} module={props.module} />
  ) : (
    <WebNavigation main={props.main} module={props.module} />
  )
}
